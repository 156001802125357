import React from "react";
import styled from "styled-components";

const ContactCard = styled.div`
  padding: 30px 20px;
  background-color: white;
  font-family: "Open Sans";
  box-shadow: 0 7px 19px 0 rgba(196, 200, 209, 0.64);
  height: 100%;
  position: relative;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const CardTitle = styled.h4`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  @media screen and (max-width: 1200px) {
    font-size: 18px;
  }
`;

const CardMeta = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 80%;
    padding-left: 0;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
`;

const StyledImage = styled.img`
  height: 70px;
  margin-bottom: 25px;
  @media screen and (max-width: 768px) {
    height: auto;
    width: 70px;
    max-height: 70px;
  }
  @media screen and (max-width: 576px) {
    height: auto;
    width: 70px;
    margin: 0 auto;
    margin-bottom: 25px;
  }
`;

const CardText = styled.p`
  color: rgba(36, 45, 65, 0.7);
  font-size: 15px;
  margin-bottom: 18px;
`;

const PhoneEmail = styled.p`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  a {
    color: #1E5BB5;
    transition: all 0.3s;
    &:hover {
      color: rgba(36, 45, 65, 0.7);
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
  @media screen and (max-width: 576px) {
    margin-left: 0px;
    text-align: center;
  }
`;

const CardBottom = styled.div`
  position: absolute;
  bottom: 20px;
  @media screen and (max-width: 768px) {
    position: relative;
    bottom: 0;
    width: 100%;
  }
`;

const CardTop = styled.div`
  margin-bottom: 60px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    width: 100%;
  }
  @media screen and (max-width: 576px) {
    text-align: center;
  }
`;

export default props => (
  <ContactCard>
    <CardTop>
      <StyledImage src={props.img} alt={props.title} />
      <CardMeta>
        <CardTitle>{props.title}</CardTitle>
        <CardText>{props.text}</CardText>
      </CardMeta>
    </CardTop>
    <CardBottom>
      <PhoneEmail>
        <a href={`mailto:${props.email}`}>{props.email}</a>
      </PhoneEmail>
      <PhoneEmail>
        <a href={`tel:0044${props.phone}`}>{props.phone}</a>
      </PhoneEmail>
    </CardBottom>
  </ContactCard>
);
