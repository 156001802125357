import React, { Component, Fragment } from "react";
import GeneralInquiries from "../assets/general-inquiries.svg";
import Sales from "../assets/sales.svg";
import { Select, DatePicker, Input, Button, message, Icon } from "antd";
import { Container, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import * as qs from "query-string";
import CAMOServices from "../assets/camo-services.svg";
import CustomerSupport from "../assets/tech-support.svg";
import styled from "styled-components";
import ContactCard from "../components/Contact/contactCard";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import GoogleMap from "../components/Contact/googleMap";
import pageStyles from "./page.module.less";
import styles from "./contact.module.less";
import SEO from "../components/SEO";
import * as Scroll from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";

const { scroller } = Scroll;

const ContactDepartment = styled.div`
  margin-bottom: 60px;
  h2 {
    margin: 0;
    font-family: "Open Sans";
    color: #242d41;
    letter-spacing: 0.04px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
      text-align: center;
    }
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 30px;
  }
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
`;

const general = {
  img: GeneralInquiries,
  title: "General Inquiries",
  text: "Have a general query? We'd love to hear from you.",
  email: "info@trustflight.com",
  phone: "+44 0330 229 0219",
};

const sales = {
  img: Sales,
  title: "Sales",
  text: "Enquire about our products or services.",
  email: "sales@trustflight.com",
  phone: "+44 0330 229 0219",
};

const camo = {
  img: CAMOServices,
  title: "CAMO Services",
  text: "Find out how you can benefit from our CAMO services.",
  email: "info.camo@trustflight.com",
  phone: "+44 0330 229 0289",
};

const support = {
  img: CustomerSupport,
  title: "Customer Support",
  text: "Need a hand? Get in touch with the TrustFlight support team.",
  email: "support@trustflight.com",
  phone: "+44 0330 229 0219",
};

const contactArray = [general, sales, camo, support];

const cards = contactArray.map((card, key) => (
  <Col md={6} lg={3} className={`${styles.contactCard} ${pageStyles.coliPad}`}>
    <ScrollAnimation animateOnce animateIn="fadeIn" offset={0} delay={key * 75}>
      <ContactCard
        key={card.title}
        img={card.img}
        title={card.title}
        text={card.text}
        email={card.email}
        phone={card.phone}
      />
    </ScrollAnimation>
  </Col>
));

const Title = styled.span`
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin: 30px 0;
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

const TitleWrapper = styled.div`
  padding: 30px 0;
  padding-bottom: 20px;
`;

const AddressTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 15px;
`;

const AddressSubTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 5px;
`;

const AddressDivider = styled.div`
  display: block;
  width: 100%;
  height: 30px;
  ${(props) => props.big && "height: 75px;"}
  ${(props) =>
    props.small && "height: 20px;"}
  @media screen and (max-width: 768px) {
    ${(props) => props.big && "height: 30px;"}
    ${(props) => props.small && "height: 30px;"}
  }
`;

const AddressText = styled.p`
  color: rgba(36, 45, 65, 0.7);
  margin-bottom: 15px;
  font-size: 15px;
`;

const AddressEmail = styled.p`
  margin: 0;
  font-size: 15px;
  a {
    color: #1e5bb5;
    &:hover {
      color: #1e5bb5;
    }
  }
`;

const StyledButton = styled.button`
  margin: 0px 8px;
  padding: 9px 20px;
  border-radius: 25px;
  color: #242d41;
  font-size: 16px;
  cursor: pointer;
  border: 1px #dcdfe2 solid;
  transition: all 0.5s;
  background: #fff;
  ${(props) =>
    props.active && "background: #1E5BB5; color: #fff; border-color: #1E5BB5;"}
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    outline: 0;
  }
  &:first-child {
    margin-left: 0;
  }
  @media screen and (max-width: 768px) {
    display: block;
    margin-left: 0;
    margin-bottom: 15px;
  }
`;

const ButtonsHolder = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;
  display: flex;
  @media screen and (max-width: 991px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
    flex-wrap: wrap;
  }
`;

const AddressWrapper = styled.div`
  margin: 15px 0;
`;

const { Option } = Select;
const { TextArea } = Input;

export default class contact extends Component {
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
    this.state = {
      activeForm: null,
      date: null,
      products: ["techlog"],
      name: null,
      email: null,
      number: null,
      company: null,
      question: null,
      hear: null,
      message_text: null,
      submitting: false,
      formSubmitted: false,
      formMessage: null,
    };
  }

  componentDidMount() {
    const { search } = this.props.location;
    if (search && search.length > 0 && search.indexOf("?") > -1) {
      this.setState({
        activeForm:
          search.indexOf("&") > -1
            ? search.replace("?", "").substring(0, search.indexOf("&") - 1)
            : search.replace("?", ""),
        products:
          search.indexOf("&") > -1
            ? search.substring(search.indexOf("&") + 1).split(",")
            : ["techlog"],
      });
      scroller.scrollTo("formArea", {
        duration: 500,
        smooth: true,
        offset: -100,
      });
    }
  }

  setForm = (form) => {
    this.setState({
      activeForm: form,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = {};
    Object.keys(this.refs).map(
      (key) =>
        (formData[key] =
          key === "form-name"
            ? this.state["activeForm"]
            : key === "date" && this.state[key]
            ? this.state[key].format("YYYY-MM-DD")
            : key === "products"
            ? this.state[key].join(", ")
            : this.state[key])
    );
    console.log(formData);
    const axiosOptions = {
      url: this.props.location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData),
    };

    // FORM VALIDATION
    const {
      activeForm,
      date,
      products,
      name,
      email,
      message_text,
      question,
      hear,
    } = this.state;

    if (!name) {
      message.error("Please fill in name.");
      return;
    }

    if (!email) {
      message.error("Please fill in email.");
      return;
    }

    if (activeForm === "question") {
      if (!question) {
        message.error("Please fill in question.");
        return;
      }
    }
    if (activeForm === "demo") {
      if (!products || products.length === 0) {
        message.error("Please select products/services you're interested in.");
        return;
      }
      if (!date) {
        message.error("Please select a preferable date.");
        return;
      }
    }
    if (activeForm === "other") {
      if (!message_text) {
        message.error("Please fill in message.");
        return;
      }
    }

    this.setState({
      submitting: true,
    });
    console.log(axiosOptions);
    axios(axiosOptions)
      .then((response) => {
        this.setState({
          submitting: false,
          name: null,
          email: null,
          company: null,
          question: null,
          message_text: null,
          date: null,
          hear: null,
          products: [],
        });
        this.formSuccess();
      })
      .catch((err) => {
        this.setState({
          submitting: false,
        });
        this.formError();
      });
  };

  formSuccess = () => {
    this.setState({
      formSubmitted: "success",
      formMessage:
        "Thank you for contacting us. A member of our team will respond as quickly as possible.",
    });
  };

  formError = () => {
    this.setState({
      formSubmitted: "error",
      formMessage:
        'Unfortunately we encountered a problem submitting your enquiry. Please try again or email <a href="mailto:info@trustflight.com">info@trustflight.com</a> instead.',
    });
  };

  getContactDetailsForm = () => {
    return (
      <Fragment>
        <Form.Group>
          <Form.Label className={styles.formLabel} for="name">
            Full name
          </Form.Label>
          <Input
            type="text"
            placeholder="Your Name"
            className={styles.formElement}
            ref="name"
            name="name"
            onChange={(e) => {
              this.setState({ name: e.target.value, formMessage: null });
            }}
            value={this.state.name}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className={styles.formLabel} for="email">
            Email address
          </Form.Label>
          <Input
            type="email"
            placeholder="Your Email"
            className={styles.formElement}
            ref="email"
            name="email"
            onChange={(e) => {
              this.setState({ email: e.target.value, formMessage: null });
            }}
            value={this.state.email}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className={styles.formLabel} for="number">
            Phone number <span>(optional)</span>
          </Form.Label>
          <Input
            type="number"
            placeholder="Your Phone Number"
            className={styles.formElement}
            ref="number"
            name="number"
            onChange={(e) => {
              this.setState({ number: e.target.value, formMessage: null });
            }}
            value={this.state.number}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className={styles.formLabel} for="company">
            Company <span>(optional)</span>
          </Form.Label>
          <Input
            type="text"
            placeholder="Your Company"
            className={styles.formElement}
            ref="company"
            name="company"
            onChange={(e) => {
              this.setState({ company: e.target.value, formMessage: null });
            }}
            value={this.state.company}
          />
        </Form.Group>
      </Fragment>
    );
  };

  getQuestionForm = () => {
    return (
      <Fragment>
        <input
          ref="form-name"
          type="hidden"
          name="form-name"
          value="question"
        />
        <Row>
          <Col lg={3} md={4} sm={6}>
            {this.getContactDetailsForm()}
          </Col>
          <Col xl={5} lg={6} md={8} sm={6}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className={styles.formLabel}>Question</Form.Label>
              <TextArea
                rows="5"
                placeholder="Your Question"
                className={styles.formTextarea}
                ref="question"
                onChange={(e) => {
                  this.setState({
                    question: e.target.value,
                    formMessage: null,
                  });
                }}
                value={this.state.question}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className={styles.formLabel}>
                Where did you hear about us?
              </Form.Label>
              <Select
                className={styles.formSelect}
                placeholder="Please select..."
                ref="hear"
                onChange={(e) => {
                  this.setState({ hear: e, formMessage: null });
                }}
                value={this.state.hear}
              >
                <Option value="searchEngine">Search engine</Option>
                <Option value="socialMedia">Social media</Option>
                <Option value="industryPublications">
                  Industry publications
                </Option>
                <Option value="industryEvents">Industry events</Option>
                <Option value="wordOfMouth">Word of mouth</Option>
              </Select>
            </Form.Group>
          </Col>

          <Col xl={8} lg={9} md={12} className={styles.formSubmitCol}>
            <Button
              variant="primary"
              htmlType="submit"
              className={styles.formSubmit}
              loading={this.state.submitting}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  };
  getDemoForm = () => {
    return (
      <Fragment>
        <input ref="form-name" type="hidden" name="form-name" value="demo" />
        <Row>
          <Col lg={3} md={4} sm={6}>
            {this.getContactDetailsForm()}
          </Col>
          <Col xl={5} lg={6} md={8} sm={6}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className={styles.formLabel}>
                Preferable day
              </Form.Label>
              <DatePicker
                className={styles.formDatePicker}
                placeholder="Please select date"
                ref="date"
                onChange={(e) => {
                  this.setState({ date: moment(e), formMessage: null });
                }}
                value={this.state.date}
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className={styles.formLabel}>
                Select products/services you're interested in
              </Form.Label>
              <Select
                mode="multiple"
                defaultValue="techlog"
                className={styles.formSelect}
                placeholder="Please select products"
                ref="products"
                onChange={(e) => {
                  this.setState({ products: e, formMessage: null });
                }}
                value={this.state.products}
              >
                <Option value="techlog">Tech Log</Option>
                <Option value="centrik">Centrik</Option>
                <Option value="logbook">Digital Engine Log</Option>
                <Option value="camo">CAMO</Option>
                <Option value="taskcards">Task Cards</Option>
                <Option value="registry">Aircraft Registry</Option>
              </Select>
            </Form.Group>
            <Form.Group>
              <Form.Label className={styles.formLabel}>
                Where did you hear about us?
              </Form.Label>
              <Select
                className={styles.formSelect}
                placeholder="Please select..."
                ref="hear"
                onChange={(e) => {
                  this.setState({ hear: e, formMessage: null });
                }}
                value={this.state.hear}
              >
                <Option value="searchEngine">Search engine</Option>
                <Option value="socialMedia">Social media</Option>
                <Option value="industryPublications">
                  Industry publications
                </Option>
                <Option value="industryEvents">Industry events</Option>
                <Option value="wordOfMouth">Word of mouth</Option>
              </Select>
            </Form.Group>
          </Col>
          <Col xl={8} lg={9} md={12} className={styles.formSubmitCol}>
            <Button
              variant="primary"
              htmlType="submit"
              className={styles.formSubmit}
              loading={this.state.submitting}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  };
  getOtherForm = () => {
    return (
      <Fragment>
        <input ref="form-name" type="hidden" name="form-name" value="other" />
        <Row>
          <Col lg={3} md={4} sm={6}>
            {this.getContactDetailsForm()}
          </Col>
          <Col xl={5} lg={6} md={8} sm={6}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className={styles.formLabel}>Message</Form.Label>
              <TextArea
                rows="5"
                placeholder="Your Message"
                className={styles.formTextarea}
                ref="message_text"
                onChange={(e) => {
                  this.setState({
                    message_text: e.target.value,
                    formMessage: null,
                  });
                }}
                value={this.state.message_text}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className={styles.formLabel}>
                Where did you hear about us?
              </Form.Label>
              <Select
                className={styles.formSelect}
                placeholder="Please select..."
                ref="hear"
                onChange={(e) => {
                  this.setState({ hear: e, formMessage: null });
                }}
                value={this.state.hear}
              >
                <Option value="searchEngine">Search engine</Option>
                <Option value="socialMedia">Social media</Option>
                <Option value="industryPublications">
                  Industry publications
                </Option>
                <Option value="industryEvents">Industry events</Option>
                <Option value="wordOfMouth">Word of mouth</Option>
              </Select>
            </Form.Group>
          </Col>
          <Col xl={8} lg={9} md={12} className={styles.formSubmitCol}>
            <Button
              variant="primary"
              htmlType="submit"
              className={styles.formSubmit}
              loading={this.state.submitting}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  };

  render() {
    const { activeForm, formSubmitted, formMessage } = this.state;
    return (
      <Layout>
        <SEO
          title="Get in touch"
          description="Our team is happy to answer any questions you might have."
        />
        <PageHeader
          header="Get in touch"
          subHeader="Our team is happy to answer any questions you might have."
        />
        <GradientSection>
          <Container>
            <Row
              className={`justify-content-md-center ${pageStyles.pageParentRow}`}
            >
              <Col md={11}>
                <ContactDepartment>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInDown"
                    offset={0}
                  >
                    <h2>Contact a specific department</h2>
                  </ScrollAnimation>
                  <Row>{cards}</Row>
                </ContactDepartment>
              </Col>
            </Row>
          </Container>
        </GradientSection>
        <Container>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRowMedium}`}
            name="formArea"
          >
            <Col md={11}>
              <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                <Title>Or let us know how we can help you today.</Title>
              </ScrollAnimation>
              <ButtonsHolder>
                <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                  <StyledButton
                    onClick={() =>
                      this.setForm(
                        activeForm === "question" ? null : "question"
                      )
                    }
                    active={activeForm === "question"}
                  >
                    I have a question
                  </StyledButton>
                </ScrollAnimation>
                <ScrollAnimation
                  animateOnce
                  animateIn="fadeInLeft"
                  offset={0}
                  delay={150}
                >
                  <StyledButton
                    onClick={() =>
                      this.setForm(activeForm === "demo" ? null : "demo")
                    }
                    active={activeForm === "demo"}
                  >
                    I would like to schedule a demo or call
                  </StyledButton>
                </ScrollAnimation>
              </ButtonsHolder>
              {formSubmitted && formMessage && (
                <div className={styles.formMessage}>
                  {formSubmitted === "success" ? (
                    <Icon
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#10A377"
                    />
                  ) : (
                    <Icon
                      type="close-circle"
                      theme="twoTone"
                      twoToneColor="#A9265B"
                    />
                  )}
                  <span dangerouslySetInnerHTML={{ __html: formMessage }} />
                </div>
              )}
            </Col>
            <Col md={11}>
              <form
                name="question"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={(event) => this.handleSubmit(event)}
              >
                <input type="hidden" name="name" />
                <input type="hidden" name="email" />
                <input type="hidden" name="number" />
                <input type="hidden" name="company" />
                <input type="hidden" name="question" />
                <input type="hidden" name="hear" />
                {activeForm === "question" &&
                  formSubmitted !== "success" &&
                  this.getQuestionForm()}
              </form>
            </Col>
            <Col md={11}>
              <form
                name="demo"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={(event) => this.handleSubmit(event)}
              >
                <input type="hidden" name="name" />
                <input type="hidden" name="email" />
                <input type="hidden" name="number" />
                <input type="hidden" name="company" />
                <input type="hidden" name="date" />
                <input type="hidden" name="products" />
                <input type="hidden" name="hear" />
                {activeForm === "demo" &&
                  formSubmitted !== "success" &&
                  this.getDemoForm()}
              </form>
            </Col>
            <Col md={11}>
              <form
                name="other"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={(event) => this.handleSubmit(event)}
              >
                <input type="hidden" name="name" />
                <input type="hidden" name="email" />
                <input type="hidden" name="number" />
                <input type="hidden" name="company" />
                <input type="hidden" name="message_text" />
                <input type="hidden" name="hear" />
                {activeForm === "other" &&
                  formSubmitted !== "success" &&
                  this.getOtherForm()}
              </form>
            </Col>
          </Row>

          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRowMedium}`}
          >
            <Col md={12} className={styles.locationsCol}>
              <Row>
                <Col md={12}>
                  <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                    <TitleWrapper>
                      <Title>Our Locations</Title>
                    </TitleWrapper>
                  </ScrollAnimation>
                </Col>
                <Col
                  md={12}
                  className={`${styles.desktopMap} ${pageStyles.coliPad}`}
                >
                  <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                    <GoogleMap />
                  </ScrollAnimation>
                </Col>
                <Col
                  md={12}
                  className={`${styles.locationText} ${pageStyles.coliPad}`}
                >
                  <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                    <Row>
                      <Col
                        md={12}
                        className={`${styles.mobileMap} ${pageStyles.coliPad}`}
                      >
                        <GoogleMap zoom={2} />
                      </Col>
                      <Col md={4}>
                        <AddressWrapper>
                          <AddressTitle>England</AddressTitle>
                          <AddressSubTitle>TrustFlight</AddressSubTitle>
                          <AddressText>
                            34 Hamilton Terrace, <br />
                            Leamington Spa, CV32 4LY
                          </AddressText>
                          <AddressEmail>
                            <a href="mailto:info@trustflight.com">
                              info@trustflight.com
                            </a>
                          </AddressEmail>
                          <AddressDivider small />
                          <AddressSubTitle>TrustFlight CAMO</AddressSubTitle>
                          <AddressText>
                            Basepoint, Great Marlings, <br />
                            Luton, LU2 8DL
                          </AddressText>
                          <AddressEmail>
                            <a href="mailto:info.camo@trustflight.com">
                              info.camo@trustflight.com
                            </a>
                          </AddressEmail>
                          <AddressDivider small />
                          <AddressSubTitle>TrustFlight Centrik</AddressSubTitle>
                          <AddressText>
                            Building 510, Churchill Way Biggin Hill Airport
                            <br />
                            Kent, TN16 3BN
                          </AddressText>
                          <AddressEmail>
                            <a href="mailto:info@centrik.net">
                              info@centrik.net
                            </a>
                          </AddressEmail>
                        </AddressWrapper>
                      </Col>
                      <Col md={4}>
                        <AddressWrapper>
                          <AddressTitle>Scotland</AddressTitle>
                          <AddressSubTitle>
                            TrustFlight Edinburgh
                          </AddressSubTitle>
                          <AddressText>
                            No. 2 Lochrin Square, 96 Fountainbridge, <br />
                            Edinburgh, EH3 9QA
                          </AddressText>
                          <AddressEmail>
                            <a href="mailto:info@trustflight.com">
                              info@trustflight.com
                            </a>
                          </AddressEmail>
                          <AddressDivider />
                          <AddressTitle>Jersey</AddressTitle>
                          <AddressSubTitle>TrustFlight Jersey</AddressSubTitle>
                          <AddressText>
                            1st Floor Forum 4, Grenville Street, St Helier,{" "}
                            <br />
                            Jersey, JE2 4UF
                          </AddressText>
                          <AddressEmail>
                            <a href="mailto:info@trustflight.com">
                              info@trustflight.com
                            </a>
                          </AddressEmail>
                        </AddressWrapper>
                      </Col>
                      <Col md={4}>
                        <AddressWrapper>
                          <AddressTitle>USA</AddressTitle>
                          <AddressSubTitle>
                            TrustFlight Jacksonville
                          </AddressSubTitle>
                          <AddressText>
                            Suite 3159, 1601-1 N Main St, <br />
                            Jacksonville, FL 32206
                          </AddressText>
                          <AddressEmail>
                            <a href="mailto:info@trustflight.com">
                              info@trustflight.com
                            </a>
                          </AddressEmail>
                          <AddressDivider />
                          <AddressTitle>Canada</AddressTitle>
                          <AddressSubTitle>
                            TrustFlight Vancouver
                          </AddressSubTitle>
                          <AddressText>
                            Suite 501, 535 Thurlow St, <br />
                            Vancouver, BC, V6E 3L2
                          </AddressText>
                          <AddressEmail>
                            <a href="mailto:info@trustflight.com">
                              info@trustflight.com
                            </a>
                          </AddressEmail>
                        </AddressWrapper>
                      </Col>
                    </Row>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}
